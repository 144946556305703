<template>
    <section class="pt-4">
        <div class="row mx-0 mb-3">
            <div class="col-3 pl-0">
                <p class="text-general f-12 pl-3">
                    Seleccionar Equipos
                </p>
                <el-select v-model="teamSelected" filterable placeholder="Seleccionar" class="w-100" size="small" @change="onChangeTeam()">
                    <el-option
                    v-for="team in teamsUnselected"
                    :key="team.id"
                    :label="team.nombre"
                    :value="team.id"
                    />
                </el-select>
            </div>
            <div class="col pt-3">
                <div class="row mx-0">
                    <div v-for="data in teamsSelected" :key="data.id" class="tag-equipo mr-2 px-2 mb-2">
                        <span class="f-14">{{ data.nombre }}</span>
                        <i class="icon-cancel f-17 ml-2 cr-pointer" @click="unselectTeam(data)" />
                    </div>
                </div>
                <div class="row mx-0 pt-2 j-center">
                    <button class="btn btn-general" @click="onInit()">
                        Mostrar Comparativa
                    </button>
                </div>
            </div>
        </div>
        <section class="position-relative">
            <cargando v-if="cargandoPrincipal" />

            <div class="row mx-0 mt-2 position-relative">
                <div class="col bg-white py-3 br-8 mr-2">
                    <div class="row mx-0 mb-4">
                        <div class="col-auto px-0 f-18 f-500 text-general">
                            Ventas en los últimos 7 días
                        </div>
                        <div v-if="teamsSelected.length > 5" class="cr-pointer bg-general3 text-white br-20 f-14 px-3 d-middle-center ml-auto" @click="verMasVentas(1)">
                            Ver más
                        </div>
                    </div>
                    <div v-for="data in ventasUltimaSemana" :key="data.id" class="row mx-0 pl-4 align-items-end mb-3">
                        <div class="col-4 pl-0">
                            <p class="f-16 text-general">
                                {{ data.nombre }}
                            </p>
                            <p class="f-16 text-general2">
                                <i>
                                    {{ separadorNumero(data.total) }}
                                </i>
                            </p>
                        </div>
                        <div class="col pb-3">
                            <el-popover
                            placement="bottom"
                            width="300"
                            popper-class="p-0 br-12 px-3 py-3"
                            trigger="hover"
                            >
                                <p class="text-general f-12 f-600 mb-2 pl-4">
                                    {{ data.nombre }}
                                </p>
                                <div v-for="rango in data.rangos " :key="rango.fecha" class="row mx-0">
                                    <div :style="`width:12px;height:12px;background-color:${rango.color}`" />
                                    <div class="col px-0 text-center text-general2">
                                        {{ formatearFecha(rango.fecha, 'dddd DD') }}
                                    </div>
                                    <div class="col px-0 text-center text-general2">
                                        {{ separadorNumero(rango.valor) }}
                                    </div>
                                </div>
                                <div slot="reference" class="row mx-0 bg-fondo" style="height:10px;">
                                    <div v-for="rango in data.rangos" :key="rango.fecha" class="h-100" :style="`background-color:${rango.color};width:${rango.porcentaje}%;`" />
                                </div>
                            </el-popover>
                        </div>
                    </div>
                </div>
                <div class="col bg-white py-3 br-8 ml-2">
                    <div class="row mx-0 mb-4">
                        <div class="col-auto px-0 f-18 f-500 text-general">
                            Ventas en los últimos 12 meses
                        </div>
                        <div v-if="teamsSelected.length > 5" class="cr-pointer bg-general3 text-white br-20 f-14 px-3 d-middle-center ml-auto" @click="verMasVentas(2)">
                            Ver más
                        </div>
                    </div>
                    <div v-for="data in ventasUltimoAnio" :key="data.id" class="row mx-0 pl-4 align-items-end mb-3">
                        <div class="col-4 pl-0">
                            <p class="f-16 text-general">
                                {{ data.nombre }}
                            </p>
                            <p class="f-16 text-general2">
                                <i>
                                    {{ separadorNumero(data.total) }}
                                </i>
                            </p>
                        </div>
                        <div class="col pb-3">
                            <el-popover
                            placement="bottom"
                            width="300"
                            popper-class="p-0 br-12 px-3 py-3"
                            trigger="hover"
                            >
                                <p class="text-general f-12 f-600 mb-2 pl-4">
                                    {{ data.nombre }}
                                </p>
                                <div v-for="rango in data.rangos " :key="rango.fecha" class="row mx-0">
                                    <div :style="`width:12px;height:12px;background-color:${rango.color}`" />
                                    <div class="col px-0 text-center text-general2">
                                        {{ formatearFecha(rango.fecha, 'MMMM') }}
                                    </div>
                                    <div class="col px-0 text-center text-general2">
                                        {{ separadorNumero(rango.valor) }}
                                    </div>
                                </div>
                                <div slot="reference" class="row mx-0 bg-fondo" style="height:10px;">
                                    <div v-for="rango in data.rangos" :key="rango.fecha" class="h-100" :style="`background-color:${rango.color};width:${rango.porcentaje}%;`" />
                                </div>
                            </el-popover>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row mx-0 mt-3">
                <div class="col bg-white br-8 mr-2 py-3">
                    <div class="row mx-0 mb-4">
                        <div class="col-auto px-0 f-18 f-500 text-general">
                            Valor del inventario
                        </div>
                        <div v-if="teamsSelected.length > 5" class="cr-pointer bg-general3 text-white br-20 f-14 px-3 d-middle-center ml-auto" @click="modalGris(1)">
                            Ver más
                        </div>
                    </div>
                    <echart :options="valor_inventario" />
                </div>
                <div class="col bg-white br-8 ml-2 py-3">
                    <div class="row mx-0 mb-4">
                        <div class="col-auto px-0 f-18 f-500 text-general">
                            Tipos de clientes
                        </div>
                        <div v-if="teamsSelected.length > 5" class="cr-pointer bg-general3 text-white br-20 f-14 px-3 d-middle-center ml-auto" @click="verMasVentas(3)">
                            Ver más
                        </div>
                    </div>
                    <echart :options="tipos_clientes" />
                </div>
            </div>
            <div class="row mx-0 mt-3">
                <div class="col bg-white br-8 mr-2 py-3">
                    <div class="row mx-0 mb-4">
                        <div class="col-auto px-0 f-18 f-500 text-general">
                            Equipos calificados de los últ. 12 meses
                        </div>
                        <div v-if="teamsSelected.length > 5" class="cr-pointer bg-general3 text-white br-20 f-14 px-3 d-middle-center ml-auto" @click="modalGris(2)">
                            Ver más
                        </div>
                    </div>
                    <echart :options="calificacion" />
                </div>
                <div class="col bg-white br-8 ml-2 py-3">
                    <div class="row mx-0 mb-4">
                        <div class="col-auto px-0 f-18 f-500 text-general">
                            Calificaciones dadas al cedis en los últ. 12 meses
                        </div>
                        <div v-if="teamsSelected.length > 5" class="cr-pointer bg-general3 text-white br-20 f-14 px-3 d-middle-center ml-auto" @click="modalGris(3)">
                            Ver más
                        </div>
                    </div>
                    <echart :options="calificacionCedis" />
                </div>
            </div>
        </section>

        <comparacion-fecha :teams="teamsSelected" :changed="changed" /> 
       
        <!-- Partials -->
        <modal-ventas-ultimos ref="ventasUltimos" :teams="teamsSelected" />
        <modal-progress-gris ref="progressGris" :teams="teamsSelected" />
    </section>
</template>

<script>

import comparativa from "~/services/teams/comparativa"

let colorGeneral = getComputedStyle(document.documentElement) .getPropertyValue('--color-general')

const colors = ['#01B8AA','#374649', '#FD625E', '#F2C80F', '#5F6B6D', '#8AD4EB', '#FE9666', '#3599B8', '#72D4CC', '#A66999', '#E1B0B0']

const  sumValor = (team) =>  team.rangos.reduce((acc, el) => acc += el.valor, 0)

const mapper = (el) => {
    const total = sumValor(el)
    el.rangos = el.rangos.map((ele, index) => {
        ele.color = colors[index]
        ele.porcentaje = total > 0 ? ((ele.valor * 100) / total) : 0
        return ele
    })

    el.total = total
    return el
}

const simpleBar = {
    tooltip: {
        trigger: 'axis',
        axisPointer: {
            type: 'shadow'
        }
    },
    xAxis: {
        type: 'category',
        show: false
    },
    dataset:{
        dimensions:[],
        source: []
    },
    yAxis: {
        type: 'value',
        axisLine: {
            lineStyle: {
                color: '#F4F4F4'
            }
        },
        axisLabel: {
            color: '#707070'
        },
        splitLine: {
            lineStyle: {
                color: '#F4F4F4'
            }
        }
    },
    series: [
        {
            type: 'bar',
            barWidth: 50,
            itemStyle: {
                color: '#DBDBDB'
            }
        },
    ]
}

export default {
    components: {
        modalVentasUltimos: () => import('./partials/modalVentasUltimos.vue'),
        modalProgressGris: () => import('./partials/modalProgressGris.vue'),
        comparacionFecha: () => import('./components/comparacionFecha.vue')
    },
    data(){
        return {
            valor_inventario: _.cloneDeep(simpleBar),
            calificacion: _.cloneDeep(simpleBar),
            calificacionCedis: _.cloneDeep(simpleBar),
            clientes_asociados: {
                tooltip: {
                    trigger: 'axis',
                    backgroundColor: 'rgb(255, 255, 255)',
                    borderColor: '#F5F5F5',
                    borderWidth: 1,
                    formatter: () => {
                        let msg = `
                        <p class="f-500 text-general pl-4"> Alfa </p> 
                        <i class="icon-blank text-general2" />
                        <span class="f-13 text-general2"> $ 4.500.000 </span>
                        `;
                        
                        return msg
                    }
                },
                xAxis: {
                    type: 'category',
                    data: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'],
                    show: false
                },
                yAxis: {
                    type: 'value',
                    axisLine: {
                        lineStyle: {
                            color: '#F4F4F4'
                        }
                    },
                    axisLabel: {
                        color: '#707070'
                    },
                    splitLine: {
                        lineStyle: {
                            color: '#F4F4F4'
                        }
                    }
                },
                series: [
                    {
                        data: [120, 200, 150, 80, 70, 110, 130],
                        type: 'bar',
                        barWidth: 50,
                        itemStyle: {
                            color: '#DBDBDB'
                        }
                    }
                ]
            },
            value1: '',
            
            
            colors,
            teams: [],
            teamSelected: '',
            ventasUltimaSemana: [],
            ventasUltimoAnio: [],
            tipoClientes: [],
            changed: false,
            cargandoPrincipal: true,
        }
    },
    computed: {
        teamsSelected(){
            return this.teams.filter(el => el.selected)
        },
        teamsUnselected(){
            return this.teams.filter(el => !el.selected)
        },
        tipos_clientes(){
            const nombres = this.tipoClientes.map(el => el.nombre)
            
            const tipos = this.tipoClientes.reduce((acc, el) => {
                acc = [...acc, ...el.tipos]
                return acc
            }, [])

            const grouped = _.chain(tipos)
                .groupBy('id')
                .map((el, key)=>{
                    return {
                        name: el[0].tipo,
                        type: 'bar',
                        stack: 'total',
                        emphasis: {
                            focus: 'series'
                        },
                        data: el.map(el => el.valor)
                    }
                 
                })

                .value()

            return {
                tooltip: {
                    trigger: 'axis',
                    axisPointer: {
                        type: 'shadow'
                    }
                },
                xAxis: {
                    type: 'category',
                    data: nombres,
                    show: false
                },
                yAxis: {
                    type: 'value',
                    axisLabel: {
                        color: '#707070'
                    },
                    splitLine: {
                        lineStyle: {
                            color: '#F4F4F4'
                        }
                    },
                    axisLine: {
                        lineStyle: {
                            color: '#F4F4F4'
                        }
                    },
                },
                series: grouped
            }
        }
    },
    
    async mounted(){
        await this.listarTeams()
        await this.onInit()
    },
    methods: {
        verMasVentas(tipo){
            this.$refs.ventasUltimos.toggle(tipo);
        },
        modalGris(tipo){
            this.$refs.progressGris.toggle(tipo);
        },
        async listarTeams(){
            try {
                const { data } = await comparativa.listarTeams();
                this.teams = data.teams.map((el,index) => ({...el, selected: index < 5}))

            } catch (error){
                this.error_catch(error)
            }
        },
        async onInit(){
            this.cargandoPrincipal = true
            this.changed = !this.changed

            await this.primerasGraficas()
        },
        onChangeTeam(){
            const find = this.teams.find(el => el.id == this.teamSelected)
            find.selected = !find.selected
            this.teamSelected = ''
        },
        unselectTeam(team){
            team.selected = !team.selected
        },
        async primerasGraficas(){
            try {
                this.cargandoPrincipal = true
                const teams = this.teamsSelected.map(el => el.id).toString()
                const { data } = await comparativa.primerasGraficas({ teams })
                this.ventasUltimaSemana = data.ventasUltimaSemana.map(mapper)
                this.ventasUltimoAnio = data.ventasUltimoAnio.map(mapper)
                this.valor_inventario.dataset = {
                    dimensions: ['nombre', 'inventario'],
                    source: data.inventario
                }
                this.tipoClientes = data.tipoClientes

                this.calificacion.dataset = {
                    dimensions: ['nombre', 'valor', 'promedio'],
                    source: data.calificacionUltimoAnio
                }


                this.calificacionCedis.dataset = {
                    dimensions: ['nombre', 'valor', 'promedio'],
                    source: data.calificacionCedisUltimoAnio
                }

                const yAxis = [
                    {
                        type: 'value',
                        scale: true,
                    // name: 'Valor',
                    },
                    {
                        type: 'value',
                        name: 'Porcentaje',
                        position: 'right',
                        alignTicks: true,
                        axisLine: {
                            show: true,
                            lineStyle: {
                                color: colorGeneral,
                            }
                        },
                    }
                ]

                const series = [
                    {
                        name: 'Calificación',
                        type: 'bar',
                        barMaxWidth: 30,
                        itemStyle: {
                            normal: {
                                color: '#DBDBDB',
                            },
                        },
                    },
                    {
                        name: 'Porcentaje',
                        symbol: 'circle',
                        type: 'line',
                        yAxisIndex: 1,
                        itemStyle: {
                            normal: {
                                color: colorGeneral,
                            },
                        },
                    },
                ]

                this.calificacion.yAxis = yAxis
                this.calificacion.series = series
                this.calificacionCedis.yAxis = yAxis
                this.calificacionCedis.series = series

            } catch (error){
                this.error_catch(error)    
            } finally{
                this.cargandoPrincipal = false
            }

        },
       
       
    }
}
</script>
<style lang="scss" scoped>
.tag-equipo{
    min-width: 100px;
    height: 32px;
    border-radius: 16px;
    border: 1px solid #DBDBDB;
    background-color: #F5F5F5;
    color: #000000;
    display: flex;
    align-items: center;
    justify-content: center;
}
</style>