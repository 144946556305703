import axios from 'axios'

const api = "teams/comparativa";

const comparativa = {
    listarTeams: params => axios.get(`${api}/listar-teams`, { params }),
    primerasGraficas: params => axios.get(`${api}/primeras-graficas`, { params }),
    segundasGraficas: params => axios.get(`${api}/segundas-graficas`, { params }),
    tercerasGraficas: params => axios.get(`${api}/terceras-graficas`, { params }),
    cuartasGraficas: params => axios.get(`${api}/cuartas-graficas`, { params }),
    quintasGraficas: params => axios.get(`${api}/quintas-graficas`, { params }),

    
    ventasUltimaSemana: params => axios.get(`${api}/ventas-ultima-semana`, { params }),
    ventasUltimoAnio: params => axios.get(`${api}/ventas-ultimo-anio`, { params }),
    tipoClientes: params => axios.get(`${api}/tipo-clientes`, { params }),
    calificacionUltimoAnio: params => axios.get(`${api}/calificacion-ultimo-anio`, { params }),
    calificacionCedisUltimoAnio: params => axios.get(`${api}/calificacion-cedis-ultimo-anio`, { params }),
    leecherosMasVentas: params => axios.get(`${api}/leecheros-mas-ventas`, { params }),
    ventasCantidadPedidos: params => axios.get(`${api}/ventas-cantidad-pedidos`, { params }),
    ticketPromedio: params => axios.get(`${api}/ticket-promedio`, { params }),
    inventario: params => axios.get(`${api}/inventario`, { params }),
    categorias: params => axios.get(`${api}/categorias`, { params }),
    productosVenta: params => axios.get(`${api}/productos-venta`, { params }),
    productosCantidad: params => axios.get(`${api}/productos-cantidad`, { params }),
    generoCantidad: params => axios.get(`${api}/genero-cantidad`, { params }),
    dispositivoCantidad: params => axios.get(`${api}/dispositivo-cantidad`, { params }),
};

export default comparativa
